<template>
    <k-layout>
        <div class="tw-relative tw-isolate tw-overflow-hidden tw-bg-gray-100">
            <div class="tw-mx-auto tw-max-w-7xl tw-pb-24 tw-pt-10 sm:tw-pb-32 lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-8 lg:tw-px-8 lg:tw-py-40">
                <div class="tw-px-6 lg:tw-px-0 lg:tw-pt-4">
                    <div class="tw-mx-auto tw-max-w-2xl">
                        <div class="">
                            <h1 class="tw-mt-10 tw-text-2xl sm:tw-text-3xl tw-text-gray-800 tw-leading-normal tw-mb-6 tw-font-bold">
                                Team profesionálov
                            </h1>

                            <p class="tw-mt-6 tw-text-gray-800 sm:tw-text-lg">
                                Sme hrdými zástupcami Koderia brandu a naša snaha spočíva v tom, aby sme vám poskytli najlepších developerov, ktorí presne
                                vyhovujú vašim potrebám pre projekty. Sprostredkúvame prácu do firiem, ktoré sú
                                <span class="tw-font-bold ">lídrami vo svojom obore</span> na Slovensku, v Česku a Rakúsku. Pod záštitou máme už desiatky
                                programátorov a programátoriek.
                            </p>

                            <p class="tw-mt-6 tw-text-gray-800 sm:tw-text-lg">
                                Naša spoločnosť je tiež súčasťou renomovanej firmy Objectify, čo nám umožňuje spojiť našu flexibilitu a osobný prístup s
                                podporou a zázemím softwarehousu.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="tw-mt-20 sm:tw-mt-24 md:tw-mx-auto md:tw-max-w-2xl lg:tw-mx-0 lg:tw-mt-0 lg:tw-w-screen tw-relative tw-hidden lg:tw-block">
                    <div class="tw-relative tw-px-6 md:tw-pl-16 md:tw-pr-0">
                        <div class="tw-mx-auto tw-max-w-2xl md:tw-mx-0 md:tw-max-w-none">
                            <div class="tw-w-screen tw-overflow-hidden tw-rounded-l-3xl tw-bg-koderia-alt">
                                <div class="tw-pt-2 tw-pb-0.5 tw-pl-1 tw-relative">
                                    <VueSlickCarousel v-bind="heroCarouselSettings" class="tw-w-full tw-rounded-2xl tw-overflow-hidden" ref="heroCarousel">
                                        <div class="tw-mx-1">
                                            <div class="tw-rounded-2xl tw-relative tw-overflow-hidden  tw-w-96 tw-h-96" style="width: 384px; height: 384px">
                                                <img class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover" src="./assets/hero/1.jpg" />
                                            </div>
                                        </div>
                                        <div class="tw-mx-1">
                                            <div class="tw-rounded-2xl tw-relative tw-overflow-hidden  tw-w-96 tw-h-96" style="width: 384px; height: 384px">
                                                <img class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover" src="./assets/hero/2.jpg" />
                                            </div>
                                        </div>
                                        <div class="tw-mx-1">
                                            <div class="tw-rounded-2xl tw-relative tw-overflow-hidden  tw-w-96 tw-h-96 " style="width: 384px; height: 384px">
                                                <img class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover" src="./assets/hero/3.jpg" />
                                            </div>
                                        </div>
                                    </VueSlickCarousel>
                                    <div
                                        class="tw-absolute tw-w-full tw-h-full tw-inset-0 tw-bg-koderia-alt tw-opacity-75 tw-cursor-pointer"
                                        style="left:392px"
                                        @click="$refs.heroCarousel.next()"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="tw-pointer-events-none tw-absolute tw-inset-0 tw-ring-1 tw-ring-inset ring-black/10 md:tw-rounded-3xl" aria-hidden="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-bg-white" id="stats">
            <div class="tw-mx-auto tw-max-w-2xl sm:tw-max-w-7xl tw-py-12 tw-px-6 lg:tw-px-8 lg:tw-py-24">
                <div class="tw-grid tw-grid-rows-5 tw-gap-8 lg:tw-grid-rows-1 lg:tw-grid-cols-5 lg:tw-gap-x-6 tw-mx-auto tw-max-w-2xl lg:tw-max-w-none">
                    <div>
                        <h3 class="tw-font-bold tw-text-gray-800 tw-text-3xl sm:tw-text-4xl" data-animate data-animate-to="10" data-animate-suffix="+">
                            0
                        </h3>
                        <p class="tw-font-semibold tw-text-gray-500 sm:tw-text-lg">
                            Rokov skúseností<br class="tw-hidden lg:tw-block" />
                            na trhu
                        </p>
                    </div>
                    <div>
                        <h3 class="tw-font-bold tw-text-gray-800 tw-text-3xl sm:tw-text-4xl" data-animate data-animate-to="200" data-animate-suffix="+">
                            0
                        </h3>
                        <p class="tw-font-semibold tw-text-gray-500 sm:tw-text-lg">
                            Spokojných IT<br class="tw-hidden lg:tw-block" />
                            špecialistov
                        </p>
                    </div>
                    <div>
                        <h3 class="tw-font-bold tw-text-gray-800 tw-text-3xl sm:tw-text-4xl" data-animate data-animate-to="150" data-animate-suffix="+">
                            0
                        </h3>
                        <p class="tw-font-semibold tw-text-gray-500 sm:tw-text-lg">
                            Mesačný nárast<br class="tw-hidden lg:tw-block" />
                            kandidátov v databáze
                        </p>
                    </div>
                    <div>
                        <h3 class="tw-font-bold tw-text-gray-800 tw-text-3xl sm:tw-text-4xl" data-animate data-animate-to="420">
                            0
                        </h3>
                        <p class="tw-font-semibold tw-text-gray-500 sm:tw-text-lg">
                            Spokojných<br class="tw-hidden lg:tw-block" />
                            klientov
                        </p>
                    </div>
                    <div>
                        <h3 class="tw-font-bold tw-text-gray-800 tw-text-3xl sm:tw-text-4xl" data-animate data-animate-to="234345">
                            0
                        </h3>
                        <p class="tw-font-semibold tw-text-gray-500 sm:tw-text-lg">
                            Úspešne dodaných<br class="tw-hidden lg:tw-block" />
                            mandayov
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-bg-gray-100" id="rocket">
            <div class="tw-mx-auto tw-max-w-2xl sm:tw-max-w-7xl tw-py-12 tw-px-6 lg:tw-px-8 lg:tw-py-24">
                <h2 class="tw-text-gray-800 tw-text-2xl sm:tw-text-3xl tw-leading-normal tw-font-bold tw-text-center">Naša cesta k úspechu</h2>
                <div class="rocket tw-flex tw-flex-col tw-items-center tw-mt-24  tw-relative">
                    <div class="tw-relative rocket-part tw-opacity-25 lg:tw-opacity-100">
                        <div class="tw-absolute tw-w-full tw-hidden lg:tw-block" style="width: 500px; top: -48px; left: 192px">
                            <p class="tw-font-bold tw-text-2xl">2023</p>
                            <p class="tw-text-lg">
                                <k-link :to="{ name: 'Calculator' }" class="tw-text-koderia-alt tw-font-bold">Mzdová kalkulačka</k-link> s viac ako 500tis.
                                použitiami
                            </p>
                        </div>
                        <div class="tw-absolute tw-left-48 tw-w-full tw-hidden lg:tw-block" style="width: 300px; top: 0; left: -330px">
                            <p class="tw-font-bold tw-text-2xl tw-text-right">2022</p>
                            <p class="tw-text-lg tw-text-right">Prekonanie 2 miliónového obratu</p>
                        </div>
                        <img
                            src="./assets/rocket/line-1.png"
                            alt=""
                            width="120"
                            class="tw-absolute tw-hidden lg:tw-block tw-pointer-events-none tw-select-none"
                            style="top: -15px; left: 125px"
                        />
                        <img
                            src="./assets/rocket/line-2.png"
                            alt=""
                            width="120"
                            class="tw-absolute tw-hidden lg:tw-block tw-pointer-events-none tw-select-none"
                            style="top: 33px; left: -104px"
                        />
                        <img src="./assets/rocket/r1.png" alt="" width="150" class="tw-pointer-events-none tw-select-none" />
                    </div>
                    <div class="tw-relative rocket-part tw-opacity-25 lg:tw-opacity-100">
                        <div class="tw-absolute tw-w-full tw-hidden lg:tw-block" style="width: 350px; top: 0px; left: 250px">
                            <p class="tw-font-bold tw-text-2xl">2021</p>
                            <p class="tw-text-lg">
                                Vznik <k-link :to="{ name: 'cv-landing-page' }" class="tw-text-koderia-alt tw-font-bold">Koderia životopisu</k-link> a možnosti
                                prihlásenia sa na portál
                            </p>
                        </div>
                        <img
                            src="./assets/rocket/line-3.png"
                            alt=""
                            width="100"
                            class="tw-absolute tw-hidden lg:tw-block tw-pointer-events-none tw-select-none"
                            style="top: 33px; left: 205px"
                        />
                        <img src="./assets/rocket/r2.png" alt="" width="173" class="tw--mt-0.5 tw-pointer-events-none tw-select-none" />
                    </div>
                    <div class="tw-relative rocket-part tw-opacity-25 lg:tw-opacity-100">
                        <div class="tw-absolute tw-w-full tw-hidden lg:tw-block" style="width: 350px; top: 0px; left: 350px">
                            <p class="tw-font-bold tw-text-2xl">2019</p>
                            <p class="tw-text-lg">Vznik brandu <span class="tw-text-koderia-alt tw-font-bold">koderia.sk</span></p>
                        </div>
                        <div class="tw-absolute tw-left-48 tw-w-full tw-hidden lg:tw-block" style="width: 300px; top: -90px; left: -330px">
                            <p class="tw-font-bold tw-text-2xl tw-text-right">2020</p>
                            <p class="tw-text-lg tw-text-right">
                                Spustenie bezkonkurenčnej
                                <k-link :to="{ name: 'Calculator' }" class="tw-text-koderia-alt tw-font-bold">Mzdovej kalkulačky</k-link>
                            </p>
                        </div>
                        <img
                            src="./assets/rocket/line-4.png"
                            alt=""
                            width="120"
                            class="tw-absolute tw-hidden lg:tw-block tw-pointer-events-none tw-select-none"
                            style="top: -57px; left: -87px"
                        />
                        <img
                            src="./assets/rocket/line-3.png"
                            alt=""
                            width="100"
                            class="tw-absolute tw-hidden lg:tw-block tw-pointer-events-none tw-select-none"
                            style="top: 33px; left: 305px"
                        />
                        <img src="./assets/rocket/r3.png" alt="" width="278" style="margin-left: 1px" class="tw--mt-1 tw-pointer-events-none tw-select-none" />
                    </div>

                    <div class="tw-relative rocket-part tw-opacity-25 lg:tw-opacity-100">
                        <div class="tw-absolute tw-w-full tw-hidden lg:tw-block" style="width: 300px; top: 10px; left: 270px">
                            <p class="tw-font-bold tw-text-2xl">2018</p>
                            <p class="tw-text-lg">
                                Štart portálu Kóderi Kóderom / Spustenie služby
                                <k-link :to="{ name: 'Questionaire' }" class="tw-text-koderia-alt tw-font-bold">Adekvátny plat</k-link>
                            </p>
                        </div>
                        <img
                            src="./assets/rocket/line-6.png"
                            alt=""
                            width="120"
                            class="tw-absolute tw-hidden lg:tw-block tw-pointer-events-none tw-select-none"
                            style="top: 11px; left: 218px"
                        />
                        <img src="./assets/rocket/r4.png" alt="" width="217" class="tw--mt-2 tw-pointer-events-none tw-select-none" />
                    </div>
                    <div class="tw-relative tw-opacity-25 lg:tw-opacity-100">
                        <img src="./assets/rocket/r5.png" alt="" width="400" class="tw-pointer-events-none tw-select-none" />
                    </div>
                    <div class="tw-absolute tw-space-y-8 lg:tw-hidden">
                        <div>
                            <p class="tw-font-bold tw-text-2xl">2023</p>
                            <p class="tw-text-lg">
                                <k-link :to="{ name: 'Calculator' }" class="tw-text-koderia-alt tw-font-bold">Mzdová kalkulačka</k-link> s viac ako 500tis.
                                použitiami
                            </p>
                        </div>

                        <div>
                            <p class="tw-font-bold tw-text-2xl tw-text-right">2022</p>
                            <p class="tw-text-lg tw-text-right">Prekonanie 2 miliónového obratu</p>
                        </div>

                        <div>
                            <p class="tw-font-bold tw-text-2xl">2021</p>
                            <p class="tw-text-lg">
                                Vznik <k-link :to="{ name: 'cv-landing-page' }" class="tw-text-koderia-alt tw-font-bold">Koderia životopisu</k-link> a možnosti
                                prihlásenia sa na portál
                            </p>
                        </div>

                        <div>
                            <p class="tw-font-bold tw-text-2xl tw-text-right">2020</p>
                            <p class="tw-text-lg tw-text-right">
                                Spustenie bezkonkurenčnej
                                <k-link :to="{ name: 'Calculator' }" class="tw-text-koderia-alt tw-font-bold">Mzdovej kalkulačky</k-link>
                            </p>
                        </div>

                        <div>
                            <p class="tw-font-bold tw-text-2xl">2019</p>
                            <p class="tw-text-lg">Vznik brandu <span class="tw-text-koderia-alt tw-font-bold">koderia.sk</span></p>
                        </div>

                        <div>
                            <p class="tw-font-bold tw-text-2xl tw-text-right">2018</p>
                            <p class="tw-text-lg tw-text-right">
                                Štart portálu Kóderi Kóderom / Spustenie služby
                                <k-link :to="{ name: 'Questionaire' }" class="tw-text-koderia-alt tw-font-bold">Adekvátny plat</k-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tw-bg-gray-100">
            <div class="tw-mx-auto tw-max-w-2xl sm:tw-max-w-7xl tw-py-12 tw-px-6 lg:tw-px-8 lg:tw-py-24">
                <h2 class="tw-text-gray-800 tw-text-2xl sm:tw-text-3xl tw-leading-normal tw-font-bold">Výhody outsoucingu s Koderiou</h2>
                <div class="lg:tw-pl-24 tw-mt-24 tw-space-y-24">
                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-24">
                        <img src="./assets/art-1.svg" alt="" width="260" class="tw-hidden lg:tw-block tw-select-none tw-pointer-events-none" />
                        <div>
                            <p class="tw-text-lg">
                                <span class="tw-block tw-font-bold tw-text-koderia-alt sm:tw-text-xl">12+ tisíc kontraktorov v našej internej databáze,</span>
                                <span class="tw-block tw-mt-8">
                                    pričom ku každému programátorovi máme skills rozdelené do kompetenčných centier, kde vieme presne zacieliť pracovné ponuky.
                                    Naši špecialisti majú širokú škálu odborných zručností, vrátane software developmentu, bezpečnosti dát, cloudových
                                    technológií a mnoho ďalších oblastí IT.
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-24">
                        <img src="./assets/art-2.svg" alt="" width="260" class="tw-hidden lg:tw-block tw-select-none tw-pointer-events-none" />
                        <div>
                            <p class="tw-text-lg">
                                <span class="tw-block tw-font-bold tw-text-koderia-alt sm:tw-text-xl">Osobný prístup</span>
                                <span class="tw-block tw-mt-8">
                                    Ku každému kontraktorovi / programátorovi pristupujeme ako keby robil v našej firme. Veríme, že náš tím profesionálnych IT
                                    špecialistov vám môže poskytnúť nielen vysoko kvalitné IT služby, ale aj pocit práce s výnimočným tímom, ktorý je plne
                                    oddaný úspechu vašich projektov.
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-24">
                        <img src="./assets/art-3.svg" alt="" width="260" class="tw-hidden lg:tw-block tw-select-none tw-pointer-events-none" />
                        <div>
                            <p class="tw-text-lg">
                                <span class="tw-block tw-font-bold tw-text-koderia-alt sm:tw-text-xl">Kvalitný výberový proces</span>
                                <span class="tw-block tw-mt-8">
                                    Každý člen tímu prešiel dôkladným výberovým procesom a má skúsenosti s prácou na rôznych projektoch v rôznych odvetviach.
                                    Poskytujeme profesionálnych IT špecialistov s bohatými skúsenosťami a odbornými znalosťami na projekty našich klientov.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-bg-gray-800">
            <div class="tw-mx-auto tw-max-w-2xl sm:tw-max-w-7xl tw-py-12 tw-px-6 lg:tw-px-8 lg:tw-py-24">
                <h2 class="tw-text-gray-50 tw-text-2xl sm:tw-text-3xl tw-leading-normal tw-font-bold">Koderia Team</h2>
            </div>
        </div>

        <div class="tw-bg-gray-800 tw-pb-24">
            <div class="tw-relative">
                <VueSlickCarousel v-bind="teamCarouselSettings">
                    <template #prevArrow>
                        <div
                            class="custom-arrow tw-w-32 lg:tw-w-64 xl:tw-w-96 tw-z-10 tw-bg-gradient-to-l tw-from-transparent tw-to-gray-800"
                            style="height: 500px"
                        ></div>
                    </template>
                    <template #nextArrow>
                        <div
                            class="custom-arrow tw-w-32 lg:tw-w-64 xl:tw-w-96 tw-z-10 tw-bg-gradient-to-r tw-from-transparent tw-to-gray-800"
                            style="height: 500px"
                        ></div>
                    </template>
                    <div class="tw-flex-shrink-0 tw-w-80 tw-mx-4" v-for="user in users" :key="user.name">
                        <div class="tw-relative">
                            <img class="shrink-0 tw-w-80 tw-h-80 tw-object-cover tw-rounded-3xl tw-shadow-xl tw-bg-white" :src="user.photo" />
                            <a
                                v-if="user.linkedIn"
                                :href="user.linkedIn"
                                class="tw-absolute tw-bottom-4 tw-right-12 tw-w-8 tw-h-8 tw-bg-gray-800 tw-rounded-full tw-grid tw-place-items-center tw-text-white"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="LinkedIn"
                            >
                                <svg
                                    class="tw-w-5 tw-h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0,0,256,256"
                                    width="30px"
                                    height="30px"
                                >
                                    <g
                                        fill="#ffffff"
                                        fill-rule="nonzero"
                                        stroke="none"
                                        stroke-width="1"
                                        stroke-linecap="butt"
                                        stroke-linejoin="miter"
                                        stroke-miterlimit="10"
                                        stroke-dasharray=""
                                        stroke-dashoffset="0"
                                        font-family="none"
                                        font-weight="none"
                                        font-size="none"
                                        text-anchor="none"
                                        style="mix-blend-mode: normal"
                                    >
                                        <g transform="scale(8.53333,8.53333)">
                                            <path
                                                d="M9,25h-5v-15h5zM6.501,8c-1.383,0 -2.501,-1.121 -2.501,-2.501c0,-1.38 1.12,-2.499 2.501,-2.499c1.378,0 2.499,1.121 2.499,2.499c0,1.38 -1.121,2.501 -2.499,2.501zM27,25h-4.807v-7.3c0,-1.741 -0.033,-3.98 -2.499,-3.98c-2.503,0 -2.888,1.896 -2.888,3.854v7.426h-4.806v-15.011h4.614v2.051h0.065c0.642,-1.18 2.211,-2.424 4.551,-2.424c4.87,0 5.77,3.109 5.77,7.151c0,0 0,8.233 0,8.233z"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div class="px-4 py-6">
                            <p class="tw-text-koderia-alt tw-font-bold tw-text-xl">{{ user.name }}</p>
                            <p class="tw-text-white tw-font-bold tw-text-xl">{{ user.role }}</p>
                            <p class="tw-text-white tw-font-bold tw-text-xl">{{ user.phone }}</p>
                            <p class="tw-text-white tw-font-bold tw-text-xl">{{ user.email }}</p>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>

        <div id="contact" class="tw-bg-white">
            <div class="tw-mx-auto tw-max-w-2xl sm:tw-max-w-7xl tw-py-12 tw-px-6 lg:tw-px-8 lg:tw-py-24">
                <h2 class="tw-text-gray-800 tw-text-2xl sm:tw-text-3xl tw-leading-normal tw-font-bold">Kontaktujte nás</h2>
                <p class="tw-mt-6 tw-text-gray-900 sm:tw-text-lg lg:tw-max-w-lg">
                    Vaša spätná väzba je pre nás veľmi dôležitá, pretože nám umožňuje zlepšovať naše služby a prispievať k vytváraniu lepšej komunity pre
                    programátorov.
                </p>
            </div>
        </div>

        <div class="tw-bg-gray-100">
            <div class="tw-mx-auto tw-max-w-2xl sm:tw-max-w-7xl tw-py-12 tw-px-6 lg:tw-px-8 lg:tw-py-24">
                <div class="tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-8">
                    <div>
                        <div class="tw-pointer-events-none tw-select-none" aria-hidden="">
                            <img src="./assets/contact-illustration.svg" alt="" />
                        </div>
                        <div class="tw-mt-4">
                            <ul class="tw-space-y-4">
                                <li>
                                    <div class="tw-flex tw-flex-row tw-gap-6 tw-items-center">
                                        <div class="tw-w-14 tw-h-14 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-gray-800">
                                            <k-icon name="phone" class="tw-text-koderia" />
                                        </div>
                                        <a href="tel:+421917863972">0917 863 972</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="tw-flex tw-flex-row tw-gap-6 tw-items-center">
                                        <div class="tw-w-14 tw-h-14 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-gray-800">
                                            <k-icon name="email" class="tw-text-koderia" />
                                        </div>
                                        <a href="mailto:info@koderia.sk">info@koderia.sk</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="tw-flex tw-flex-row tw-gap-6 tw-items-center">
                                        <div class="tw-w-14 tw-h-14 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-gray-800">
                                            <k-icon name="location" class="tw-text-koderia" />
                                        </div>
                                        <a href="https://goo.gl/maps/aEZesckuq1mQFHw2A" target="_blank" rel="noopener noreferrer">
                                            Objectify, s. r. o.<br />
                                            Martinengova 4881/36 811 02 Bratislava<br />
                                            Office: Dúbravská cesta 2, 841 04 Bratislava (Westend Tower)
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tw-mt-12">
                        <p class="tw-mt-6 tw-text-gray-900 sm:tw-text-lg">
                            Sme tu, aby sme vám pomohli s informáciami a našli riešenia, ktoré vyhovujú vašim potrebám.
                        </p>

                        <form @submit.prevent="handleSubmitContactForm">
                            <div class="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6 tw-mt-6">
                                <k-input white type="text" name="name" class="tw-w-full" placeholder="Meno a priezvisko *" required />
                                <k-input white type="text" name="email" autocomplete="email" class="tw-w-full" placeholder="Email *" required />
                            </div>
                            <div class="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6 tw-mt-6">
                                <k-input white type="text" name="phone" autocomplete="phone" class="tw-w-full" placeholder="Telefón *" required />
                                <k-input white type="text" name="url" autocomplete="url" class="tw-w-full" placeholder="Spoločnosť alebo Linkedin profil" />
                            </div>
                            <div class="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6 tw-mt-6">
                                <k-textarea white class="tw-w-full" name="message" placeholder="Správa *" required />
                            </div>
                            <div class="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6 tw-mt-6">
                                <k-checkbox label="Súhlasím so spracovaním osobných údajov" />
                            </div>
                            <div class="tw-flex tw-justify-end  lg:tw-flex-row tw-flex-col tw-gap-6 tw-mt-6">
                                <k-button type="submit">
                                    Odoslať
                                </k-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </k-layout>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import { scroll, animate, inView } from "motion";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: "AboutUsPage",

    components: { VueSlickCarousel },

    data() {
        return {
            heroCarouselSettings: {
                arrows: true,
                dots: false,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 4000,
                cssEase: "ease",
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            },
            teamCarouselSettings: {
                arrows: true,
                dots: false,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 4000,
                cssEase: "ease",
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "32px",
                variableWidth: true
            },
            users: [
                {
                    photo: "/web/koderia-team/kr_pe.jpg",
                    name: "Kristína Petreková",
                    role: "Managing Director",
                    phone: "+421 917 863 972",
                    email: "petrekova@objectify.sk",
                    linkedIn: "https://www.linkedin.com/in/kristína-petreková-frániková-53184a182"
                },
                {
                    photo: "/web/koderia-team/de_an.jpg",
                    name: "Denis Angelmeier",
                    role: "Key Account Manager",
                    phone: "+421 948 021 233",
                    email: "angelmaier@objectify.sk",
                    linkedIn: "https://www.linkedin.com/in/denis-angelmaier-99598894"
                },
                {
                    photo: "/web/koderia-team/ja_py.jpg",
                    name: "Jakub Pytel",
                    role: "Account Manager",
                    phone: "+421 904 871 359",
                    email: "pytel@objectify.sk",
                    linkedIn: "https://www.linkedin.com/in/jakub-pytel-6770068a"
                },
                {
                    photo: "/web/koderia-team/ja_ko.jpg",
                    name: "Jana Kováčiková",
                    role: "IT Recruiter",
                    phone: "+421 917 569 711",
                    email: "kovacikova@objectify.sk",
                    linkedIn: ""
                },
                {
                    photo: "/web/koderia-team/da_la.jpg",
                    name: "David Lazar",
                    role: "Fullstack Developer",
                    phone: "+421 911 961 029",
                    email: "lazar@objectify.sk",
                    linkedIn: "https://www.linkedin.com/in/d%C3%A1vid-l%C3%A1z%C3%A1r-9085b3161"
                }
            ]
        };
    },
    mounted() {
        inView(
            "#stats",
            () => {
                const elements = document.querySelectorAll("[data-animate]");
                elements.forEach(el => {
                    const from = el.dataset.animateFrom || 0;
                    const to = el.dataset.animateTo || 1;
                    const suffix = el.dataset.animateSuffix || "";
                    animate(
                        progress => {
                            el.innerHTML = Math.round(from + progress * to) + "" + suffix;
                        },
                        { duration: 1.6, easing: "ease-out" }
                    );
                });
            },
            { amount: 0.4 }
        );

        this.$nextTick(() => {
            const parts = document.querySelectorAll(".rocket-part");

            const secondItem = document.querySelector("#rocket");

            scroll(
                ({ y }) => {
                    const ratio = 1 - Number(y.progress.toFixed(2));
                    parts.forEach(part => {
                        if (part && part.style) {
                            part.style.marginTop = 160 * ratio + "px";
                        }
                    });
                },
                {
                    target: secondItem,
                    offset: ["start end", "end end"]
                }
            );
        });
    },

    methods: {
        handleSubmitContactForm(evt) {
            const data = new FormData(evt.target);
            const value = Object.fromEntries(data.entries());
            firebase.functions().httpsCallable("processContactForm")(value);
        }
    }
};
</script>

<style lang="scss">
@import "@web/sass/variables.scss";

.custom-arrow::before {
    content: "";
    display: none;
}
</style>
